<template>
  <div class="team-wrapper">
    <h2 class="team-title">OUR plus TEAM</h2>
    <p class="team-description">Overnight team consists of 20+ members, including 12 members of technical team.</p>
    <div class="team-image-wrapper">
      <img
        class="team-image"
        :src="require('@/assets/audits/team.svg')"
        alt="team"
      />
    </div>
    <button
      @click="onClick"
      class="button-team"
    >
      meet the team
      <img
        :src="require('@/assets/icons/arrow-left.svg')"
        alt="arrow"
      />
    </button>
  </div>
</template>

<script setup>
const onClick = () => {
  window.open('https://docs.overnight.fi/other/team', '_blank').focus();
};
</script>

<style>
.team-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #0f172a;
  border-radius: 30px;
  padding: 30px 40px;
  max-width: 490px;
  height: 505px;
  background-color: #bee179;
  gap: 20px;
  width: 100%;

  @media only screen and (max-width: 1120px) {
    max-width: initial;
  }
  @media only screen and (max-width: 650px) {
    max-width: initial;
    height: initial;
    padding: 15px;
  }
}

.team-title {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}
.team-description {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
}
.team-image-wrapper {
  justify-content: center;
  width: 100%;
}
.team-image {
  height: 100%;

  @media only screen and (max-width: 650px) {
    width: 100%;
    max-height: 358px;
  }
}
.button-team {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #0f172a;
  border: 1px solid #0f172a;
  border-radius: 100px;
  padding: 5px 20px;
  width: 219px;
  height: 36px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  line-height: 18px;
}
</style>
