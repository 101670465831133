<template>
  <button
    class="button-audit"
    @click="onClick"
  >
    Read full report
    <img
      :src="require('@/assets/icons/arrow-left.svg')"
      alt="arrow"
    />
  </button>
</template>

<script setup>
const onClick = () => {
  window.open('https://docs.overnight.fi/other/audits', '_blank').focus();
};
</script>

<style scoped>
.button-audit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  color: #ffffff;
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 32px;
  padding: 0px 20px;
  width: 222px;
  height: 36px;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
}
</style>
