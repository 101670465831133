<template>
  <div class="divider-trust" />
</template>

<style scoped>
.divider-trust {
  height: 2px;
  width: 100%;
  background: #0f172a;
}
</style>
